import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav, Form } from "react-bootstrap"

import Logo from "./logo"

import ReactGA from "react-ga"

const CustomNavbar = ({ pageInfo, title }) => {
  return (
    <>
      <header>
        <Navbar variant="dark" expand="lg" id="site-navbar" className="py-4">
          <Logo title={title} imgBgW={44} imgBgH={44} imgConW={34} imgConH={24}/>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto header-nav" activeKey={pageInfo && pageInfo.pageName}>
              <Link to="/" className="link-no-style">
                <Nav.Link as="span" eventKey="index">
                  Home
                </Nav.Link>
              </Link>
              <Link to="/about" className="link-no-style">
                <Nav.Link as="span" eventKey="About">
                  About
                </Nav.Link>
              </Link>
            </Nav>
            <Form className="suggest-section" inline onSubmit={e => e.preventDefault()}>
              <ReactGA.OutboundLink eventLabel="Suggest" to="https://forms.gle/CxcWpzJiY7BEq4by9" target="_blank" 
                                    rel='noopener noreferrer' role="button" className="px-3 suggest-btn">
                Suggest a resource
              </ReactGA.OutboundLink>
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </>
  )
}

export default CustomNavbar
