import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import { Link } from "gatsby"

import Logo from "./logo"

import { FaTelegram, FaTwitter } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"

import ReactGA from "react-ga"

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            twitterURL
            telegramURL
            contactEmail
            copyrightName
          }
        }
      }
    `,
  )

  const title = site.siteMetadata.title
  const twitterURL = site.siteMetadata.twitterURL
  const telegramURL = site.siteMetadata.telegramURL
  const contactEmail = site.siteMetadata.contactEmail
  const copyrightName = site.siteMetadata.copyrightName

  return (
    <Container fluid className="px-0">
      <Row noGutters>
        <Col className="footer-col">
          <footer>
            <Container fluid className="p-0">
              <Row noGutters className="footer-top">
                <Col className="col-10 offset-1 col-sm-4 offset-sm-1">
                  <Logo title={title} imgBgW={65} imgBgH={65} imgConW={21} imgConH={35}/>
                </Col>
                <Col className="col-10 offset-1 col-sm-6 offset-sm-0 text-right">
                  <div className="footer-soc-icons d-flex justify-content-between ml-auto">
                    <ReactGA.OutboundLink eventLabel="Twitter" to={twitterURL} target="_blank" 
                                          rel='noopener noreferrer' aria-label="Twitter">
                      <FaTwitter/>
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink eventLabel="Telegram" to={telegramURL} target="_blank" 
                                          rel='noopener noreferrer' aria-label="Telegram">
                      <FaTelegram/>
                    </ReactGA.OutboundLink>
                  </div>
                </Col>
              </Row>
              <div className="text-center footer-links-container">
                <Link to="/privacy" className="footer-link">
                  Privacy Policy
                </Link>
              </div>
              <Row noGutters className="footer-bottom">
                <Col className="col-10 offset-1 col-sm-5 offset-sm-1">
                <span>
                  © {new Date().getFullYear()},
                  {` `} {copyrightName}
                </span>
                </Col>
                <Col className="col-10 offset-1 col-sm-5 offset-sm-0 email-column">
                  <ReactGA.OutboundLink eventLabel="Email" to={"mailto:" + contactEmail} target="_blank" 
                                        rel='noopener noreferrer' aria-label="contact email">
                    {contactEmail}
                  </ReactGA.OutboundLink>
                </Col>
              </Row>
            </Container>
          </footer>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
