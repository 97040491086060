/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, {useState, useEffect} from "react"

import { Link } from "gatsby"

import { graphql, useStaticQuery } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

import Navbar from "./navBar"
import Footer from "./footer"

import favicon from "../images/eth.svg"

import Helmet from "react-helmet"

import CookieConsent from 'react-cookie-consent';
import ReactGA from "react-ga"

const Layout = ({ children, pageInfo }) => {
  const { site } = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  )

  const title = site.siteMetadata.title

  const [gaAccepted, setGaAccepted] = useState(false)

  useEffect(() => {
    if (!gaAccepted) return
    // workaround to enable GA on cookies accept without reloading the page
    const trackingId = process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID || `` // set in Netlify settings, filter hostname in GA
    if (trackingId && trackingId.trim() !== ``) {
      ReactGA.initialize(trackingId)
      if (typeof window !== 'undefined') { // window is not defined during the build
        ReactGA.set({ page: window.location.pathname + window.location.search, anonymizeIp: true })
        ReactGA.pageview(window.location.pathname + window.location.search)
      }
      ReactGA.ga((tracker) => {
        const userId = tracker.get('clientId')
        ReactGA.event({
          category: 'Cookies',
          action: 'Accepted website cookies',
          label: userId
        })
      })
    }
  })

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon}/>
      </Helmet>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="gatsby-gdpr-google-analytics"
        onAccept={() => {
          setGaAccepted(true)
        }}
        expires={730} // 2 years
        buttonStyle={{ borderRadius: '5px', padding: '10px 15px', border: 0, marginRight: '30px' }}
        style={{ backgroundColor: "#1b1c27" }}
      >
        This website uses cookies to enhance the user experience.{` `} 
        <span style={{ fontSize: "10px" }}>
          Google Analytics service is integrated to collect and analyze traffic <b>anonymously</b>, the data is not shared with any other third-party company.{` `}
          <Link to="/privacy" style={{ color: 'white', textDecoration: 'underline' }}>
            Learn more
          </Link>
        </span>
      </CookieConsent>
      <Container fluid className="px-0 main">
        <Row noGutters>
          <Col>
            <Navbar pageInfo={pageInfo} title={title}/>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <Container fluid className="px-0">
              <main>{children}</main>
            </Container>
          </Col>
        </Row>
      </Container>
      <Footer/>
    </>
  )        
}

export default Layout
