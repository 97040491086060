import PropTypes from "prop-types"
import React from "react"

import { Navbar } from "react-bootstrap"
import { Link } from "gatsby"

import logoBackground from "../images/logo-background.svg"
import logoContent from "../images/logo-content.svg"

const Logo = ({ title, imgBgW, imgBgH, imgConW, imgConH }) => (
  <Link to="/" target="_self" className="link-no-style">
    <Navbar.Brand className="d-flex position-relative align-items-center justify-content-center">
      <img
        src={logoBackground}
        className="position-relative logo-bg"
        alt="logo"
        width={imgBgW}
        height={imgBgH}
      />
      <img
        src={logoContent}
        className="position-absolute logo-content"
        alt="logo"
        width={imgConW}
        height={imgConH}
      />
    </Navbar.Brand>
    <Navbar.Brand as="span" className="text-white">{title}</Navbar.Brand>
  </Link>
)

Logo.propTypes = {
  title: PropTypes.string,
  imgBgW: PropTypes.number,
  imgBgH: PropTypes.number,
  imgConW: PropTypes.number,
  imgConH: PropTypes.number,
}

Logo.defaultProps = {
  title: "",
  imgBgW: 0,
  imgBgH: 0,
  imgConW: 0,
  imgConH: 0,
}

export default Logo
